import dayjs from 'dayjs';
import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink, useLocation } from 'react-router-dom';
import PayMethods from '~components/footer/PayMethods';
import { useConfig } from '~components/hooks';
import { useOpenModal } from '~components/modals/hooks';
import { useProfile } from '~components/profile/hooks';
import { AuthBlock } from '~frontend/components/AuthBlock';
import playAudio from '~frontend/utils/playAudio';

import LogoFooter from '../../scss/images/footer-logo.svg';
import IconMenu1 from '../../scss/images/icon-menu-1.svg?react';
import IconMenu2 from '../../scss/images/icon-menu-2.svg?react';
import IconMenu4 from '../../scss/images/icon-menu-4.svg?react';
import IconMenu6 from '../../scss/images/icon-menu-6.svg?react';
import IconMenu8 from '../../scss/images/icon-menu-8.svg?react';
import IconEarn from '../../scss/images/icon-menu-14.svg?react';
import IconMenu9 from '../../scss/images/icon-menu-9.svg?react';
import Star from '../../scss/images/trustpilot-star.svg?react';
import Trustpilot from '../../scss/images/trustpilot.svg';

const Footer: FunctionComponent = () => {
  const location = useLocation();
  const { onShow } = useOpenModal();
  const { t } = useTranslation();
  const getConfig = useConfig();
  const getProfile = useProfile();
  const [isExpanded, setIsExpanded] = useState(false);

  // Список путей для главной страницы с учетом локалей
  const homePaths = [
    '/',
    '/tr',
    '/en',
    '/ru',
    '/pt',
    '/pl',
    '/de',
    '/th',
    '/hi',
  ];

  // Проверяем, находимся ли мы на главной странице
  console.log('location.pathname', location.pathname);
  const isHomePage = homePaths.includes(location.pathname);

  const isMobile =
    getConfig?.userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ) ?? false;

  const viewModal = (
    event: SyntheticEvent<HTMLAnchorElement>,
    { type, reachgoal } = event.currentTarget.dataset,
  ) => {
    if (event) {
      event.preventDefault();
    }

    if (!type) {
      throw Error('Не указан тип');
    }

    onShow(type, { reachgoal });
  };

  const goReferralPage = (event: SyntheticEvent<HTMLAnchorElement>) => {
    if (!getProfile) {
      viewModal(event, { type: 'ForgotModal' });
    } else {
      const dataEvent = event.currentTarget.getAttribute('data-event');

      if (dataEvent) {
        window?.dataLayer?.push({
          event: dataEvent,
        });
      }
    }
  };

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <footer className="footer">
      <div className="footer-top">
        {getProfile === null && !location.pathname.includes('/event/') ? (
          <>
            <AuthBlock authSocial />
            <div className="open-cases-btns">
              <a className="advantages-sign-in-steam" href="/auth/steam">
                <div className="small-text">{t('Sign in through')}</div>
                <div className="text">Steam</div>
                <div className="top"></div>
                <div className="bot-1"></div>
                <div className="bot-2"></div>
              </a>
              {getConfig?.hostname !== 'cscase.com' && (
                <a className="advantages-sign-in-vk" href="/auth/vk">
                  <div className="top"></div>
                  <div className="bot-1"></div>
                </a>
              )}
              {/* comment fb sign in */}
              {/* <a className="advantages-sign-in-fb" href="/auth/fb">
                <div className="top"></div>
                <div className="bot-1"></div>
              </a> */}
              {getConfig?.hostname === 'cscase.com' && (
                <a className="advantages-sign-in-google" href="/auth/google">
                  <div className="top"></div>
                  <div className="bot"></div>
                </a>
              )}
            </div>
          </>
        ) : null}
        <nav className="header-menu">
          <a
            target="_blank"
            rel="nofollow"
            href="/referral"
            onClick={goReferralPage}
            onMouseEnter={playAudio}
            data-audio="3.mp3"
            data-volume="0.15"
            className="header-menu__item promocode"
          >
            <div className="header-menu__item-icon">
              <IconMenu1 viewBox="0 0 32 32" />
            </div>
            <span className="header-menu__item-name">{t('Earn')}</span>
          </a>
          <NavLink
            to="/faq"
            onMouseEnter={playAudio}
            data-audio="3.mp3"
            data-volume="0.15"
            className="header-menu__item wheel_link"
          >
            <div className="header-menu__item-icon">
              <IconMenu6 viewBox="0 0 48 48" />
            </div>
            <span className="header-menu__item-name">{t('Faq')}</span>
          </NavLink>
          {/* <NavLink
            to="/contract"
            onMouseEnter={playAudio}
            data-audio="3.mp3"
            data-volume="0.15"
            className="header-menu__item contract"
          >
            <div className="header-menu__item-icon">
              <IconMenu2 viewBox="0 0 32 32" />
            </div>
            <span className="header-menu__item-name">{t('Contracts')}</span>
          </NavLink>

          <NavLink
            to="/upgrade"
            onMouseEnter={playAudio}
            data-audio="3.mp3"
            data-volume="0.15"
            className="header-menu__item apgrate"
          >
            <div className="header-menu__item-icon">
              <IconMenu4 viewBox="0 0 32 32" />
            </div>
            <span className="header-menu__item-name">{t('Upgrades')}</span>
          </NavLink> */}

          <NavLink
            to="/wheel"
            onClick={goReferralPage}
            onMouseEnter={playAudio}
            data-audio="3.mp3"
            data-volume="0.15"
            data-event="wheel_Footer"
            className="header-menu__item"
          >
            <div className="header-menu__item-icon">
              <IconEarn viewBox="0 0 32 32" />
            </div>
            <span className="header-menu__item-name">{t('Bonus_Wheel')}</span>
          </NavLink>

          <NavLink
            to="/reviews/video"
            onMouseEnter={playAudio}
            data-audio="3.mp3"
            data-volume="0.15"
            className="header-menu__item"
          >
            <div className="header-menu__item-icon">
              <IconMenu9 viewBox="0 0 48 48" />
            </div>
            <span className="header-menu__item-name">{t('Reviews')}</span>
          </NavLink>
        </nav>
        <div className="footer-content">
          <div className="footer-top-item">
            <div className="agree">
              <div>
                <NavLink to="/privacy_policy">{t('Privacy Policy')}</NavLink>
              </div>
              <div>
                <NavLink to="/agreement">{t('Terms of use')}</NavLink>
              </div>
              <div>
                <NavLink to="/refunds">{t('funds_return_policy')}</NavLink>
              </div>
              <div>
                <NavLink to="/contacts">{t('Contacts')}</NavLink>
              </div>
              <div>
                <NavLink to="/reviews/video">{t('Reviews')}</NavLink>
              </div>
              <div>
                <NavLink to="/blog">{t('blog')}</NavLink>
              </div>
              <div>
                <NavLink
                  to="/faq"
                  onMouseEnter={playAudio}
                  data-audio="3.mp3"
                  data-volume="0.15"
                >
                  {t('FAQ')}
                </NavLink>
              </div>
            </div>
            <a
              className="footer-support-mail"
              href={`mailto:sup@${getConfig?.hostname}`}
            >
              sup@{getConfig?.hostname}
            </a>
            <div className="copyright">
              {getConfig?.hostname.toUpperCase()} © 2017-
              {dayjs().year()}.
            </div>
          </div>
          <div className="logo">
            <img src={LogoFooter} alt="" width="486" height="122" />
            <div className="footer-address">
              {getConfig.isEU && (
                <>
                  <span>GLIMMERSYNC DYNAMICS LTD, Company Number 15623882</span>
                  <span>(128 City Road, London, United Kingdom, EC1V 2NX)</span>
                  <br />
                </>
              )}
              {/* <span>
                EY CYPRUS ADVISORY SERVICES LIMITED, Company Number ОН 42879
              </span>
              <span>
                (Stasinova 6, JEAN NOVEL TOWER, FIRST FLOOR 1060, NICOSIA,
                CYPRUS)
              </span> */}
            </div>
            {getConfig.isRU && (
              <>
                {/* <div className="footer-address">
                  <span>
                    Прием и отправка платежей через АО &quot;Киви Банк&quot;
                    осуществляется
                  </span>
                  <span>
                    ИП МУХА ВЛАДИМИР БОГДАНОВИЧ ИНН: 614588962120
                    ОГРНИП: 321619600095864
                  </span>
                </div> */}
                <div className="footer-address">
                  <span>GLIMMERSYNC DYNAMICS LTD</span>
                  <span>Reg. Number: 15623882</span>
                  <span>128, City Road, London, EC1V 2NX, UNITED KINGDOM</span>
                </div>
              </>
            )}
          </div>
          <div className="footer-top-item">
            <div className="soc-footer">
              {getConfig?.hostname === 'cscase.io' && (
                <>
                  <a
                    className="telegram"
                    href={getConfig?.seo.telegram}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="vk"
                    href={getConfig?.seo.vk}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="tiktok"
                    href="https://www.tiktok.com/@cscase.io"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="discord"
                    href="https://discord.com/invite/nQqdw4wBUv"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="youtube"
                    href="https://www.youtube.com/channel/UCF2bKbFmfvvdWFiFbj1sRfw"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="twitch"
                    href="https://www.twitch.tv/cscase17"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                </>
              )}
              {getConfig?.hostname === 'cscase.com' && (
                <>
                  <a
                    className="tw"
                    href={getConfig?.seo.twitter}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="telegram"
                    href={getConfig?.seo.telegram}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="fb"
                    href={getConfig?.seo.fb}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="instagram"
                    href={getConfig?.seo.instagram}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                  <a
                    className="discord"
                    href={getConfig?.seo.discord}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  ></a>
                </>
              )}
            </div>
            {getConfig?.hostname === 'cscase.com' && (
              <a
                className="footer-trustpilot"
                href="https://trustpilot.com/review/cscase.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="trustpilot">
                  <div className="trustpilot-text">
                    Check out our{' '}
                    <span>{getConfig?.trustpilot?.totalreviews}</span> reviews
                  </div>
                  <div className="trustpilot-stars-wrap">
                    <div className="trustpilot-stars-logo">
                      <img src={Trustpilot} />
                    </div>
                    <div className="trustpilot-stars">
                      <div className="trustpilot-stars-item">
                        <Star />
                      </div>
                      <div className="trustpilot-stars-item">
                        <Star />
                      </div>
                      <div className="trustpilot-stars-item">
                        <Star />
                      </div>
                      <div className="trustpilot-stars-item">
                        <Star />
                      </div>
                      <div className="trustpilot-stars-item">
                        <Star />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            )}
          </div>
        </div>
        {isHomePage && (
          <div className="footer-seo">
            <div className={`content ${isExpanded ? 'expanded' : ''}`}>
              <div className="text-block">{t('seo_text_pt1')}</div>
              <div className="text-block">{t('seo_text_pt2')}</div>
              <div className="text-block">{t('seo_text_pt3')}</div>
              <div className="text-block">{t('seo_text_pt4')}</div>
              <div className="text-block">{t('seo_text_pt5')}</div>
              <div className="text-block">{t('seo_text_pt6')}</div>
              <div className="text-block">{t('seo_text_pt7')}</div>
              <div className="text-block">{t('seo_text_pt8')}</div>
              <div className="text-block">{t('seo_text_pt9')}</div>
              <div className="text-block">{t('seo_text_pt10')}</div>
              <div className="text-block">{t('seo_text_pt11')}</div>
            </div>
            <div className="read-more-btn" onClick={toggleContent}>
              {isExpanded ? t('hide') : t('read_more')}
            </div>
          </div>
        )}
      </div>
      <PayMethods />
    </footer>
  );
};

export default Footer;
